.categorys-view-slider { 
    padding-left: 0px !important;
}

@media (max-width: 991.98px){
.categorys-view-slider { 
    padding-left: 0px !important;
}

}

 
.categorys-view-slider .slide-content-view {
    -moz-box-flex: 0;
    -webkit-flex: 0 0 320px;
    flex: 0 0 100%;
    width: 100%;
}

@media (max-width: 1599.98px){
.categorys-view-slider .slide-content-view {
    -moz-box-flex: 0;
    -webkit-flex: 0 0 320px;
    flex: 0 0 100%;
    width: 100%;
}}
