.radiodec {
    border: 1px solid #dee2e6;
    cursor: pointer;
}

.radio {
    width: 18px;
    height: 18px;
    position: absolute;
    border: 1px solid #dee2e6;
    top: 22px;
    left: 20px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.radio::before {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #eb6139;
    display: none;
}

input[type="radio"]:checked+.radiodec,
input[type="radio"]:checked+.radiodec .radio {
    border-color: #eb6139 !important;
}

input[type="radio"]:checked+.radiodec .radio::before {
    display: block;
}

.addreswrapper {
    display: grid;
    gap: 25px;
    grid-template-columns: 5fr 5fr;

    @media screen and (max-width:767px) {
        grid-template-columns: 1fr;
    }
}

.address-input {
    display: flex;
    margin-top: 10px;
    justify-content: space-around;

    @media (max-width: 575px) {
        flex-direction: column;
    }
}

.react-tel-input .form-control {
    width: 265px;
    height: 38px;

    @media (max-width: 575px) {
        width: 100%
    }
}

.address-text {
    @media (max-width: 575px) {
        width: 90%;
        margin-left: 13px;
    }
}