.products {
    .product-item {
        @media(min-width: 576px) {
            padding-left: 20px;
        }
    }

    .product-item-first {
        padding: 0 !important;
    }
}