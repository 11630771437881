ul {
    list-style-type: none;
}

.product-grid {
    background-color: #fff;
    text-align: center;
    position: relative;
    max-width: 300px;
    margin: auto;
    box-shadow:
        0 0 0 2px rgb(255, 255, 255),
        0.3em 0.3em 1em rgba(0, 0, 0, 10%);
    border-radius: 20px;
}

.product-grid .product-image {
    overflow: hidden;
    position: relative;
    border-radius: 0px;
}

.product-grid .product-image a.product-link-image {
    display: block;
}

.product-grid .product-image img {
    padding: 5px;
    width: 100% !important;
    height: auto !important;
    min-height: 300px;
    max-height: 300px;
    object-fit: cover;
    border-radius: 0px;
}

.product-grid .product-image .pic-1 {
    transition: all 0.3s ease 0s;
}

// .product-grid .product-image:hover .pic-1{ transform: translateX(100%); }

.product-grid .product-sale-label {
    color: #fff;
    background: #0a805e;
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 2px 8px;
    position: absolute;
    top: 15px;
    left: 15px;
}

.product-grid .product-like-icon {
    color: #696969;
    font-size: 22px;
    line-height: 20px;
    position: absolute;
    top: 15px;
    right: 15px;
}

.product-grid .product-like-icon:hover {
    color: #0a805e;
}

.product-grid .product-like-icon:before,
.product-grid .product-like-icon:after {
    content: attr(data-tip);
    color: #fff;
    background-color: #000;
    font-size: 12px;
    line-height: 18px;
    padding: 7px 7px 5px;
    visibility: hidden;
    position: absolute;
    right: 0;
    top: 15px;
    transition: all 0.3s ease 0s;
}

.product-grid .product-like-icon:after {
    content: '';
    height: 15px;
    width: 15px;
    padding: 0;
    transform: translateX(-50%) rotate(45deg);
    right: auto;
    left: 50%;
    top: 15px;
    z-index: -1;
}

.product-grid .product-like-icon:hover:before,
.product-grid .product-like-icon:hover:after {
    visibility: visible;
    top: 30px;
}

.product-grid .product-links {
    width: 100%;
    color: white;
    background: #0c0c0c;
    margin: 0;
    list-style: none;
    opacity: 0;
    transform: translateX(-50%);
    position: absolute;
    bottom: 0;
    left: 50%;
    transition: all 0.3s ease 0s;
    text-decoration: none;
    font-size: .9375rem;
}

.product-grid:hover .product-links {
    bottom: 0px;
    opacity: 1;
}

.product-grid .product-content {
    padding: 15px 10px;
    background-color: #fff;
    border-radius: 20px;
}

.product-grid .title {
    display: flex;
    justify-content: flex-start;
    font-size: 1rem;
    font-weight: 700;
    text-transform: capitalize;
    padding-left: 10px;
}

.product-grid .title a {
    color: #333;
    transition: all 0.3s ease 0s;
    text-decoration: none;
}

.product-grid .title a:hover {
    color: #0a805e;
}

.product-grid .price {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    color: #F89828 !important;
    font-size: 16px;
    font-weight: 700;
    font-size: 1rem;
}

.product-grid .price span {
    color: #555;
    font-size: 14px;
    font-weight: 400;
    text-decoration: line-through;
    margin: 0 5px 0 0;
}

// @media screen and (max-width: 990px){
//     .product-grid{ margin: 0 0 30px; }
// }

.destination {
    a {
        text-decoration: none;
        color: #000;
    }
}

.no-pro {
    padding-left: 10px;
}