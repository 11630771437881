.modal-content {
	// background-color: rgba(255, 255, 255, 0.85);
	background-color: rgba(255, 255, 255, 0);
}

.h-banner .container {
	position: relative;
	min-height: calc(100vh - 55px);
}

.b-bottom-content {
	visibility: hidden;
}

.ad {
	text-align: center;
	color: white;
	display: block;
	// display: none;

	div {
		line-height: 1.3;
	}
}


/*  Homepage Discount Banner - Edit above 2 Lines */


.ad-modal {
	border-radius: 10px;
	overflow: hidden;
}

.close-icon {
	position: absolute;
	top: 5px;
	right: 15px;
}

@media (max-width: 767.2px) {
	.h-banner .container {
		min-height: 20px;
		height: calc(100vh - 180px - 0px);
	}
}

@media (max-width: 715px) {
	.h-banner .container {
		min-height: 20px;
		height: calc(100vh - 172px - 0px);
	}
}

@media (max-width: 647.2px) {
	.h-banner .container {
		min-height: 20px;
		height: calc(100vh - 165px - 0px);
	}
}

@media (max-width: 523.2px) {
	.h-banner .container {
		min-height: 20px;
		height: calc(100vh - 155px - 0px);
	}
}

@media (max-width: 384px) {
	.h-banner .container {
		min-height: 20px;
		height: calc(100vh - 145px - 0px);
	}
}

.home-banner .owl-dots {
	position: absolute;
	top: 90%;
	right: 0;
	left: 0;
}

.bannerText {
	margin-top: 9rem;
	color: white;
}

.bannerText h2 {
	width: 80%;
}

@media (max-width: 550px) {
	.bannerText {
		margin-top: 5rem;
	}
}

.h-content {
	position: absolute;
	top: 5%;
	z-index: 1;
	left: 10%;

	@media (max-width: 550px) {
		top: 10%;
		right: 10%
	}
}

.scrollText {
	position: absolute;
	top: 50%;
	right: 0;
	letter-spacing: 0.5em;
	font-size: 13px;
	line-height: 100%;
	color: #ffffff;
	opacity: 0.7;
	transform: translateY(-50%) rotate(90deg);

	@media (max-width: 765px) {
		display: none;
	}

	img {
		margin-left: 17px;
		transform: rotate(-90deg);
	}
}

.promotion-welcome {
	font-weight: 500;
	margin-bottom: 0;

	@media (max-width: 715.2px) {
		font-size: 24px;

	}

	@media (max-width: 685.6px) {
		font-size: 22px;
	}

	@media (max-width: 631.2px) {
		font-size: 20px;
	}

	@media (max-width: 579.2px) {
		font-size: 18px;
	}

	@media (max-width: 523.2px) {
		font-size: 16px;
	}

	@media (max-width: 465.6px) {
		font-size: 14px;
	}

	@media (max-width: 383.2px) {
		font-size: 12px;
	}
}