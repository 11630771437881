.counter-container {
  background-color: rgba(255, 255, 255, 0);
}

.cart-continue:hover {
  background-color: #faba72;
}

.cart .productdetail .counter-btn {
  background-color: transparent;
  border-radius: 6px;
  padding: 20px 5px;
  display: flex;
  align-items: center;
  width: 100px;
  height: 35px;
  border: none !important;
}

.cart .productdetail .counter-btn > * {
  width: 33.33%;
  padding: 3px;
  font-size: 12px;
  color: #fff;
  background-color: #f89828;
}

.cart .productdetail .counter-btn p {
  margin: 0;
  padding: 5px;
  font-size: 16px;
  text-align: center;
  background-color: #fff6;
  color: black !important;
  font-weight: bold;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cart .summary {
  background: rgba($color: #f9fafb, $alpha: 1);
}

.cart .register-btn {
  color: white !important;
}

.cart .productdetail .product-title {
  font-size: 20px;
  font-weight: 500;
  // text-transform: uppercase;
  margin-bottom: 10px;
  color: #2e323b;

  @media (max-width: 767.2px) {
    font-size: 16px;
  }
}

.cart .productdetail .price {
  font-size: 25px;
  font-weight: 400;
  color: #202434;
}

.cart-total {
  @media (max-width: 767.2px) {
    font-size: 16px !important;
  }
}

.cart-delete {
  @media (max-width: 767.2px) {
    width: 25px !important;
    height: 25px !important;
  }
}

.cart .fixbottom {
  position: fixed;
  z-index: 1;
  bottom: 0;
  width: 100%;
  left: 0;
  background: white;
  padding: 10px 15px 5px;
  box-shadow: 0px -2px 8px 0px #0000001f;
}

.cart .product-img-card .product-image {
  object-fit: cover;
  width: 100%;
  height: 180px;
  border-radius: 5px;

  @media screen and (max-width: 767px) {
    height: 120px;
    width: 100%;
  }
}

.product-img-card {
  width: 180px;

  @media screen and (max-width: 767px) {
    width: 100px;
    min-width: 100px;
    height: 120px;
  }
}

.cart-container {
  background-color: white;
  box-shadow: 0 0 0 2px rgb(255, 255, 255), 0.3em 0.3em 1em rgba(0, 0, 0, 10%);
  border-radius: 20px;
}

.product__desc {
  @media (max-width: 767.2px) {
    display: none;
  }
}

.cart-item {
  flex-direction: row;

  @media (max-width: 767.2px) {
    flex-direction: column;
  }
}
