.hoscard-btn {
  background-color: transparent;
  border: 1px solid #eb6139;
  border-radius: 10px;
  padding: 10px 20px;
  color: #eb6139;
  width: 250px;
  height: 50px;
  min-width: max-content;
}

.hoscard-upload {
  background-color: transparent;
  border: 1px solid #eb6139;
  border-radius: 10px;
  padding: 10px 20px;
  color: #eb6139;
  width: 250px;
  height: 50px;
}

.hoscard-btn:hover {
  background-color: #eb603917;
}

.hoscard-upload:hover {
  background-color: #eb603917;
}

.fill-btn {
  background-color: #eb6139;
  color: white;
}

.hoscard-btns {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;

  @media (max-width: 600px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.preview-postcards {
  padding: 3rem;
  background-color: white;
  display: flex;
  justify-content: center;
  gap: 1rem;
  border-radius: 25px;
  box-shadow:
    0 0 0 2px rgb(255, 255, 255),
    0.3em 0.3em 1em rgba(0, 0, 0, 0.3);

  @media (max-width: 1200px) {
    flex-direction: column-reverse;
  }

  @media (max-width: 600px) {
    padding: 1rem;
  }
}

.upload-text {
  padding: 10px 50px 10px 50px;

  @media (max-width: 600px) {
    padding: 18px;
  }
}

.preview-section {
  min-width: 500px;
}

@media (max-width: 600px) {
  .preview-section {
    min-width: unset;
  }
}

.preview-title {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 1rem;
  text-align: right;

  @media (max-width: 600px) {
    text-align: center;
  }
}

.preview-desc {
  width: 100%;
  height: 400px;
  text-align: right;

  @media (max-width: 600px) {
    width: 100%;
    height: 180px;
    text-align: center;
  }
}

.upload-container {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload-title {
  text-align: center;
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 1rem;
}

.upload-desc {
  width: 100%;
  text-align: center;
}

.frame-container {
  display: flex;
  flex-direction: column-reverse;
  gap: 1rem;
}

.frame-div {
  gap: 0.5rem;
  flex: 100%;
  width: 530px;
  display: flex;
  flex-direction: row;
  overflow: auto;

  @media (max-width: 1199.2px) {}

  @media (max-width: 991.2px) {}

  @media (max-width: 767.2px) {
    width: 100%
  }
}


.parent {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  // min-height: 60%;
}

.image1 {
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;
  border-radius: 5px;
}

.image2 {
  width: 100%;
  height: 100%;
  position: absolute;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  top: 0%;
  left: 0%;
  border-radius: 5px;
}

.frame-img {
  width: 100%;
  height: 100%;
  cursor: pointer;
  margin-bottom: 0.5rem;
  position: relative;
  top: 0;
  left: 0;
  opacity: 0.7;
}

.frame-img:hover {
  opacity: 1;
}

.frame-img1 {
  width: 171px;
  height: 140px;
  position: relative;
  top: 0;
  overflow: hidden;
  left: 0;
  z-index: 1;
  border-radius: 15px;

  @media (max-width: 767.2px) {
    width: 120px;
    height: 100px;
  }
}

.frame-img2 {
  width: 171px;
  height: 140px;
  object-fit: contain;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 15px;

  @media (max-width: 767.2px) {
    width: 120px;
    height: 100px;
  }
}