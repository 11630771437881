.cards .owl-prev {
  position: absolute;
  top: 30%;
  left: 2%;
  display: block !important;
}

.cards .owl-next {
  position: absolute;
  top: 30%;
  right: 2%;
  display: block !important;
}

.hoscards-SlideCard {
  width: 100%;
  padding-top: 72%;
  position: relative;
  border-radius: 25px;
  overflow: hidden;
  margin-bottom: 3rem;
  cursor: pointer;

  .wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: inherit;
    overflow: hidden;
    transition: 0.5s;
    background-color: #f3f3f3;

    .render-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      object-fit: cover;
    }

    .dark-layer {
      z-index: 2;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg,
          rgba(0, 0, 0, 0) -5.56%,
          rgba(0, 0, 0, 0.75) 119.7%);
    }

    .price {
    z-index: 3;
    position: absolute;
    right: 10%;
    top: 5%;
    color: #eb6139;
    font-size: 1.3rem;
    background: #ffffff91;
    border-radius: 5px;
    padding: 5px 5px;
    margin-top: 5px;

    }

    .content {
      z-index: 3;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      padding: 0 30px 25px;

      .title {
        font-family: "Trebuchet MS";
        font-weight: bold;
        font-size: 24px;
        line-height: 1.166665;
        color: #ffffff;

        @media (max-width: 1599.98px) {
          font-size: 20px;
        }

        @media (max-width: 991.98px) {
          font-size: 18px;
        }
      }
    }
  }
}