.shopping-btn{
    background-color: transparent;
      border: 1px solid #eb6139;
      border-radius: 6px;
      padding: 10px 20px;
      color: #eb6139;
      width:200px;
      height: 50px;
  }
  .shopping-btn:hover{
    background-color: #eb603917;
  }
.trackorder-btn{
    background-color: #eb6139;
    color: white;
}
.trackorder-btn:hover{
    background-color: #eb603917;
    color: #eb6139;
}