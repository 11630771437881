.innerBanner .owl-stage {
  height: 600px !important;
}

.designer-container {
  z-index: 9;
  color: #ffffff;
  line-height: 1.5;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.designer-logo {
  position: absolute;
  bottom: 30%;
  right: 75%;

  @media (max-width: 1300px) {
    right: 75%;
  }

  @media (max-width: 575px) {
    bottom: 30%;
    right: 23%;
  }
}

.designer-name {
  margin-top: 10%;
  margin-left: 27.5%;
  font-size: 34px;
  font-weight: 600;
  width: fit-content;

  @media (max-width: 1200px) {
    margin-top: 15%;
  }

  @media (max-width: 575px) {
    margin: auto;
    margin-top: 100%;
    font-size: 25px;
  }
}

.designer-desc {
  position: absolute;
  bottom: 50%;
  right: 58%;
  font-size: 28px;

  @media (max-width: 1300px) {
    bottom: 50%;
    right: 54%;
  }

  @media (max-width: 575px) {
    display: none;
  }
}

.bread-crumbs {
  // @media (max-width: 575px) {
  display: none;
  // }
}
