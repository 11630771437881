.hoscard-submit {
  display: flex;
  justify-content: center;

  @media (max-width: 559.2px) {
    gap: 1rem;
    flex-direction: column;
    align-items: center;
  }
}

.parent-postcard {
  position: relative;
  top: 0;
  left: 0;
  //  border: 1px solid;
  // margin-bottom: 3rem;

  @media (max-width: 600px) {
    display: block;
  }
}

.postcard-container {
  display: flex;
  gap: 0.8rem;

  @media (max-width: 991.2px) {
    flex-direction: column;
  }
}

.postcard {
  position: relative;
  top: 0;
  left: 0;

  @media (max-width: 400px) {
    height: 250px;
  }
}

.message-container {
  display: flex;
  padding: 3rem;
  background-color: white;
  justify-content: center;
  gap: 1rem;
  border-radius: 25px;
  margin-bottom: 2rem;
  box-shadow: 0 0 0 2px rgb(255, 255, 255), 0.3em 0.3em 1em rgba(0, 0, 0, 0.3);

  @media (max-width: 767.2px) {
    flex-direction: column;
    align-items: center;
    padding: 0.5rem;
  }
}

.message-stack {
  width: 60%;

  @media (max-width: 767.2px) {
    width: 90%;
  }
}

.message-input {
  width: 100%;
}

.message-title {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 1rem;
}

.message-desc {
  width: 100%;
}

.text-message {
  border: none;
  background-color: #f0f0f0;
  border-radius: 20px;
  resize: none;
  padding: 15px;
}

.text-address {
  border: none;
  background-color: #f0f0f0;
  border-radius: 20px;
  resize: none;
  padding: 15px;
}

textarea:focus {
  outline: none;
}

.message {
  position: absolute;
  top: 17%;
  left: 5%;
  width: 40%;
  height: 60%;
  // font-size: 14px;
  word-wrap: break-word;

  // @media (max-width: 1199.2px) {
  //     font-size: 12px;
  // }

  // @media (max-width: 991.2px) {
  //     font-size: 12px;
  // }

  // @media (max-width: 767.2px) {
  //     font-size: 12px;
  // }
}

.address {
  position: absolute;
  top: 49%;
  left: 58%;
  width: 35.5%;
  font-size: 14px;
  word-wrap: break-word;
  line-height: 2.6;
  height: 100px;

  @media (max-width: 1199.2px) {
    font-size: 12px;
    line-height: 50px;
  }

  @media (max-width: 991.2px) {
    font-size: 12px;
    line-height: 44px;
  }

  @media (max-width: 767.2px) {
    font-size: 12px;
    line-height: 30px;
  }

  @media (max-width: 500px) {
    font-size: 12px;
    line-height: 21px;
  }
}

.filter-item {
  border: none;
  background-color: #f1f1f1;
  width: 100%;
  font-size: 15px;
  font-weight: 600;
  border-radius: 0.5rem;
  margin: 0 0.5rem 1rem 0.5rem;
  position: relative;
  padding: 10px;
}

.dropdown {
  padding-top: 0.5rem;
}

.button-dropdown {
  padding-top: 1rem;

  @media (max-width: 575px) {
    padding-top: 0rem;
  }
}

.style-content {
  cursor: pointer;
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 180px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  padding: 0.5rem;
  top: 85%;
  left: 10%;
}

.sub-filter {
  padding: 0.2rem;
}

.show {
  display: block;
}

.sub-filter:hover {
  background-color: #ddd;
}
