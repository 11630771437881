.about-cont {
    margin-top: 100px;
    margin-bottom: 100px;
    background: #fff;
    padding: 60px;
    border-radius: 15px;


    & .section1 {
        width: 100%;
        display: flex;
        justify-content: space-between;

        & .textsec {
            width: 60%;
        }

        & .imgsec {
            width: 30%;
            position: relative;
        }

        @media (max-width: 992px) {
            flex-direction: column;

            & .textsec {
                width: 100%;
            }

            & .imgsec {
                display: none;
            }
        }

    }
}




.textsec2 {


    color: #2E323B;
    font-size: 16px;
    font-weight: 500;
    margin-top: 70px;
    width: 100%;

    @media (max-width: 992px) {

        width: 100%;

    }
}