.collection-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;


}

.collection-div {
  display: flex;
  background-color: #fff;
  flex-direction: row;
  padding: 2px;
  height: 20%;
  border-radius: 10px;
  width: 100%;
  min-width: 175px;
  box-shadow: 0 0 0 2px #fff, 0.3em 0.3em 1em rgb(0 0 0 / 10%);

}

.collection-name {
  font-weight: bold;
  font-size: 24px;
}

.collection-desc {
  width: 90%;
  word-wrap: break-word;
}

.collection-author-img {
  height: 200px;

  border-radius: 10px;
}

@media (max-width: 765px) {
  .collection-div {
    display: flex;
    background-color: #e0e0e0;
    flex-direction: column;
    padding: 2px;
    height: 20%;
    border-radius: 10px;
    width: 100%;
  }
}


.filepond--drop-label label{

  font-weight: 500;
   color: black;
}



.filtercont{

  
          border-radius: 5px;
          display: flex;
        justify-content:space-between;
        @media (max-width: 765px) {
                
                flex-direction: column;
            
            }


            & .filterbutton{
              
              width:fit-content;
              padding: 10px 25px;
              background: #EFF5FC;
              color: #000;
              font-size: 15px;
              font-weight: 600;
              margin-right: 20px;
              border-radius:10px;
              @media (max-width: 765px) {
                margin-top:20px;
                
            
            }


              
           
            }
}