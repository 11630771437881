.container {
  @media (min-width: 1200px) {
    max-width: 1140px;
    padding: 0;
  }

  // @media (min-width: 992px) {
  //     max-width: 960px;
  // }

  // @media (min-width: 768px) {
  //     max-width: 720px;
  // }

  @media (max-width: 576px) {
    padding: 0 20px;
  }
}

.product-container {
  padding-top: 17px;
  padding-left: 5px;
  padding-bottom: 40px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 0 0 2px rgb(255, 255, 255), 0.3em 0.3em 1em rgba(0, 0, 0, 10%);

  .owl-carousel {
    .owl-prev {
      left: 5px;
      top: 40%;
    }

    .owl-next {
      right: 5px;
      top: 40%;
    }
  }
}

.productdetail .product-image {
  object-fit: cover;
  width: 100%;
  border-radius: 0px;
  // height: 100% !important;
}

.product-tag {
  color: black;
  cursor: pointer;
}

.product_details {
  @media (min-width: 992px) {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }

  .price {
    font-size: 1.5rem;
    color: #ff6f61 !important;
    font-weight: bolder;
  }

  .font-size-sm {
    font-size: 0.9375rem !important;
  }

  .font-size-lg {
    font-size: 1.125rem !important;
  }

  .text-gray-350 {
    color: #a6a6a6 !important;
  }

  .custom-select {
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 1.75rem + 2px);
    padding: 0.875rem 3.5rem 0.875rem 1.5rem;
    font-size: 1rem;
    font-weight: 400;
    outline: none;
    line-height: 1.5;
    color: #111;
    background: #fff
      url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpolyline fill='none' stroke='%23525252' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' points='6 9 12 15 18 9'/%3e%3c/svg%3e")
      no-repeat right 1.5rem center/1rem 1rem;
    vertical-align: middle;
    border: 1px solid #e5e5e5;
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
}

a {
  text-decoration: none;
}

h3 {
  font-weight: bold;
  font-size: 2rem;
}

.custom-btn {
  padding: 0.875rem 1.75rem;
  border-radius: 0;
}

.fav-parent {
  position: relative;
}

.fav-icon {
  position: absolute;
  left: 85%;
  top: 5%;
  color: lightgray;
  cursor: pointer;
  border: 1px solid;
  border-radius: 50%;
  background-color: white;
  padding: 10px;
}

.custom-btn-dark:hover {
  color: #fff;
  background-color: #0c0c0c;
  border-color: #060606;
}

.custom-btn-dark {
  color: #fff;
  background-color: #1f1f1f;
  border-color: #1f1f1f;
}

.addcart-btn {
  background-color: transparent;
  border: 1px solid #eb6139;
  border-radius: 6px;
  padding: 10px 20px;
  color: #eb6139;
  width: 200px;
  height: 50px;
}

.addcart-btn:hover {
  background-color: #eb603917;
}

.fill-btn {
  background-color: #eb6139;
  color: white;
}

.fill-btn:hover {
  color: #eb6139;
}

.custom-btn-outline-dark {
  color: #1f1f1f;
  border-color: #1f1f1f;

  &:hover {
    color: #fff;
    background-color: #1f1f1f;
    border-color: #1f1f1f;
  }
}

.counter-btn {
  background-color: transparent;
  border: 1px solid #eb6139;
  border-radius: 6px;
  padding: 0px 5px;
  color: #eb6139;
  display: flex;
  align-items: center;
  width: 200px;
  height: 50px;
}

.counter-btn > * {
  width: 33.33%;
}

.counter-btn p {
  margin: 0;
  padding: 12px;
  text-align: center;
  background-color: #eb603917;
}

.text-gray-500 {
  color: #525252 !important;
}

.custom-btn-circle.custom-btn-xxs {
  width: calc(1.40625rem + 0.375rem + 2px);
}

.text-gray-350 {
  color: #a6a6a6 !important;
}

.font-size-xxxs {
  font-size: 0.6875rem !important;
}

.custom-btn-circle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: calc(1.5rem + 1.75rem + 2px);
  padding: 0;
  border-radius: 50%;
}

.custom-btn-xxs {
  height: calc(1.40625rem + 0.375rem + 2px);
  padding: 0.1875rem 0.75rem;
  font-size: 0.9375rem;
}

.custom-btn-light {
  color: #1f1f1f;
  background-color: white;
  border-color: white;
}

.custom-control {
  padding-left: 0;
  position: relative;
  display: block;
  min-height: 1.5rem;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 0.5rem;
}

input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1.125rem;
  height: 1.3125rem;
  opacity: 0;
}

.control-label {
  color: #767676;
  cursor: pointer;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  color: #767676;
  vertical-align: top;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

.bg-cover {
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-size: cover !important;
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.custom-control-img .custom-control-label {
  width: 70px;
  opacity: 0.8;

  // &::after {
  //   top: auto;
  //   bottom: 0;
  //   left: 0;
  //   display: none;
  //   width: 100%;
  //   border-bottom: 2px solid #111;
  // }
}

.custom-control .custom-control-label {
  color: #767676;
  cursor: pointer;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.custom-control-size .custom-control-label {
  min-width: 3rem;
  padding: 0.5rem;
  text-align: center;
  border: 1px solid rgb(129, 129, 129);
  border-radius: 3px;
}

.tabs {
  padding-top: 5rem;

  .tab-list {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #e5e5e5 !important;

    .tab-list-item {
      padding: 0.875rem 0;
      outline: none;
      cursor: pointer;
      margin-left: 2rem;
      font-weight: 500;
      color: #111;

      &:hover {
        color: #ff6f61;
      }

      &:first-child {
        margin: 0 !important;
      }
    }

    .tab-list-item.active {
      color: #ff6f61;
      background-color: transparent;
      border-color: #bababa #bababa transparent;
      border-bottom: 1px solid #ff6f61;
    }

    .tab-content {
      padding-top: 3rem;
    }
  }
}

.alert-link {
  cursor: pointer;
  color: #eb6139;
}

.css-efvcc7-MuiPaper-root-MuiAlert-root .MuiAlert-icon {
  color: #eb6139 !important;
}

.custom-control-size .custom-control-input:checked ~ .custom-control-label {
  border-color: #ff6f61;
  color: #ff6f61;
  background-color: #eb603917;
}

.custom-control-size .custom-control-input:disabled ~ .custom-control-label {
  opacity: 0.5;
}

// .custom-control-img
//   .custom-control-input:checked
//   ~ .custom-control-label::after {
//   display: block;
// }

.custom-control {
  padding-left: 0px !important;
}

.custom-control-label::before {
  display: none !important;
}

.custom-control-label::after {
  display: none !important;
}

.custom-control-img .custom-control-input:checked ~ .custom-control-label {
  border: 1px solid;
  opacity: 1 !important;
}

.product-img-card {
  border: none;
  background: rgb(239, 245, 252);

  a {
    width: 100%;
  }
}

.slick-nav-img {
  width: 97px;
  height: 97px;
}

.product-detail > * {
  font-size: 16px;
}

.fixbottom {
  position: fixed;
  z-index: 10;
  bottom: 0;
  width: 100%;
  left: 0;
  background: white;
  padding: 10px 15px 5px;
  box-shadow: 0px -2px 8px 0px #0000001f;
}

.fixbottom > * {
  margin-bottom: 0;
}

.actionbutton {
  background: #f89828;
  padding: 15px 30px;
  border: none;
  font-size: 16px;
  height: fit-content;
  font-weight: 700;
  color: #fff;
  border-radius: 12px;
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.05);

  @media (max-width: 765px) {
    padding: 15px 20px;
  }
}

.actionbutton:hover {
  background-color: #faba72;
}

.submitbutton {
  background: #f89828;
  border: none;
}

.submitbutton:hover {
  background-color: #faba72;
}

.guarantees {
  @media (max-width: 567px) {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
}
