.scratchWrapper {
	display: flex;
	flex-direction: column;
	align-items: center; 
	cursor: pointer; 
}

.scratchHeader {
	width: 320px;
	text-align: center;
}


.scratchContent{

	background-image: url(https://www.pngarts.com/files/10/Confetti-PNG-Image.png);
    background-size: contain;
    background-position: bottom;
    background-blend-mode: hard-light;
	
	cursor: pointer; 
	
	
}

.scratchResult {
	width: 320px;
	height: 320px;
	border: 2px dashed lightgray;
	transition: all 0.15s ease-out;
	display: flex;
	justify-content: center;
	align-items: center;

	.scratchContent {
		padding: 10px;

        background-color: #fff;

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.voucher_code {
			font-size: 2rem;
		}

		.description {
			margin-top: 20px;
		}
	}
}
