@import url(https://fonts.googleapis.com/css?family=Lato:700);

.box {
  // overflow: inherit;
  position: relative;
}
.tab-box-btn {
  display: none;
}

/* common */
.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
  z-index: 100;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  border: 5px solid #eb1919;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 15px 0;
  background-color: #ec130c;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  font: 700 18px/1 "Lato", sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
  font-size: 15px;
  // font-size: 21px;
}

/* top left*/
.ribbon-top-left {
  top: -10px;
  left: -10px;
}
.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-top-left::before {
  top: 0;
  right: 0;
}
.ribbon-top-left::after {
  bottom: 0;
  left: 0;
}
.ribbon-top-left span {
  right: -25px;
  top: 30px;
  transform: rotate(-45deg);
}

/* top right*/
.ribbon-top-right {
  top: -10px;
  right: -10px;
}
.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbon-top-right::before {
  top: 0;
  left: 0;
}
.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}
.ribbon-top-right span {
  // left: -25px;
  // top: 30px;
  transform: rotate(45deg);
  left: -28px;
  top: 32px;
}

/* bottom left*/
.ribbon-bottom-left {
  bottom: -10px;
  left: -10px;
}
.ribbon-bottom-left::before,
.ribbon-bottom-left::after {
  border-bottom-color: transparent;
  border-left-color: transparent;
}
.ribbon-bottom-left::before {
  bottom: 0;
  right: 0;
}
.ribbon-bottom-left::after {
  top: 0;
  left: 0;
}
.ribbon-bottom-left span {
  right: -25px;
  bottom: 30px;
  transform: rotate(225deg);
}

/* bottom right*/
.ribbon-bottom-right {
  bottom: -10px;
  right: -10px;
}
.ribbon-bottom-right::before,
.ribbon-bottom-right::after {
  border-bottom-color: transparent;
  border-right-color: transparent;
}
.ribbon-bottom-right::before {
  bottom: 0;
  left: 0;
}
.ribbon-bottom-right::after {
  top: 0;
  right: 0;
}
.ribbon-bottom-right span {
  left: -25px;
  bottom: 30px;
  transform: rotate(-225deg);
}

.price_btn {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.org_price {
  text-align: right;
  width: auto;
  background-color: #eb6139;
  padding: 10px;
  border-radius: 10px;
  font-weight: bold;
  font-size: 24px;
  line-height: 1.166665;
  margin-bottom: 10px;
  white-space: nowrap;
  @media (max-width: 1366px) {
    font-size: 20px;
  }
  @media (max-width: 1024px) {
    font-size: 18px;
  }
  @media (max-width: 767px) {
    font-size: 13px;
  }
}

.bottom-div {
  .title {
    font-weight: bold;
    font-size: 24px;
    line-height: 1.25;

    @media (max-width: 1920px) {
      font-size: 24px;
    }
    @media (max-width: 1366px) {
      font-size: 20px;
    }
    @media (max-width: 1024px) {
      font-size: 14px;
      max-width: 100%;
      padding-right: 0 !important;
    }
  }
  .price {
    background-color: #eb6139;
    padding: 10px;
    border-radius: 10px;
    font-weight: bold;
    font-size: 24px;
    line-height: 1.166665;
    margin-bottom: 10px;
    white-space: nowrap;
    @media (max-width: 1366px) {
      font-size: 20px;
    }
    @media (max-width: 1024px) {
      font-size: 18px;
    }
    @media (max-width: 767px) {
      font-size: 13px;
    }
  }
}
