.promoCoupon {
	position: relative;
	width: 300px;
	height: 100px;
	margin: 0px auto;
	margin-bottom: 30px;
	background-image: radial-gradient(
			circle at 1px 8px,
			transparent 6px,
			#ff9e6d 6px,
			#ff9e6d 0px
		),
		radial-gradient(
			circle at 199px 8px,
			transparent 6px,
			#ff9e6d 6px,
			#ff9e6d 0px
		);
	background-size: 200px 18px;
	background-position: 0 0, 200px 0;
	background-repeat: repeat-y;
	color: #fff;
	font-weight: bold;
	line-height: 1;
	// padding-left: 40px;
	box-sizing: border-box;
	cursor: pointer;
	border-right: 10px dotted white;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.couponInfo {
		font-size: .85rem;
		font-weight: 500;
	}
	
	.couponContent {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 5px;
		transition: all .1s linear;
		transform: translateY(0);

		.off {
			font-size: 3em;
		}

		.useCode {
			display: flex;
			flex-direction: column;
			gap: 5px;

			.code {
				font-size: 18px;
				font-weight: bold;
			}
		}
	}

	&:hover .content {
		transform: translateY(-5px);
	}
}
