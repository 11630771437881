.innerBanner .owl-stage {
  height: 500px !important;
}

.bread-crumbs {
  // @media (max-width: 767.2px) {
  display: none !important;
  // }
}

.cart-banner {
  @media (max-width: 767.2px) {
    height: 300px !important;
  }
}
