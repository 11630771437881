.coupon__applied {
    margin-bottom: 10px;
    margin-top: 10px;
    padding: 15px 0;
    border-radius: 15px;
    border: 1px dashed green;
    color: green;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.coupon__applied .coupon__code {
    font-weight: bold;
    font-size: 1.3rem;
}

.Toastify__toast-container--top-right {
    top: 7rem !important
}