.filter-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  margin-right: 2rem;

  @media (max-width: 575px) {
    flex-direction: column;
    gap: 0rem;
    margin-right: 0rem;
  }
}

.filter-item {
  border: none;
  background-color: #eff5fc;
  width: 100%;
  font-size: 15px;
  font-weight: 600;
  border-radius: 0.5rem;
  margin: 0 0.5rem 1rem 0.5rem;
  position: relative;
  padding: 10px;
}

.dropdown {
  padding-top: 1rem;
}

.button-dropdown {
  padding-top: 1rem;

  @media (max-width: 575px) {
    padding-top: 0rem;
  }
}

.dropdown-content {
  cursor: pointer;
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 180px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 0.5rem;
}

.sub-filter {
  padding: 0.2rem;
}

.show {
  display: block;
}

.sub-filter:hover {
  background-color: #ddd;
}

.search-input {
  display: flex;
  justify-content: center;
  border: none;
  // background-color: #eff5fc;
  border-radius: 0.5rem;
  padding: 10px;
  font-size: 15px;
  font-weight: 600;
  outline: none;

  // width: 30%;
  // margin-bottom: 1rem;
  @media (max-width: 575px) {
    margin-top: 1rem;
  }
}

.select-collections {
  display: flex;
  justify-content: center;
  border: none;
  border-radius: 0.5rem;
  font-size: 15px;
  font-weight: 600;
  padding: 10px;
  appearance: none;
  outline: none;
  background-color: #eff5fc;
  // margin-bottom: 15px;
  cursor: pointer;
}

.option-collections {
  background-color: #f1f1f1;
}

.option-collections:hover {
  background-color: red;
}
