$grey-light: hsl(200, 10%, 92%);
$grey: hsl(200, 10%, 85%);
$grey-dark: hsl(200, 10%, 70%);
$shadow: hsla(200, 20%, 20%, 0.25);
$red: #dc143c;
$green: #0af00a;

$bg: hsl(200, 0%, 100%);
$divider: $grey-light;
$border: $grey-dark;

$cutout-size: 1rem;

*,
*::after {
  box-sizing: border-box;
  margin: 0; 
}
 
.ticket { 
  display: grid;
  grid-template-rows: auto 1fr auto;
  max-width: 24rem;
  @media (max-width: 575px) {
    width: 100%;
  }
  &__header,
  &__header_success,
  &__header_normal,
  &__body,
  &__footer {
    padding: 1.25rem;
    background-color: $bg;
    border: 1px solid $border;
    box-shadow: 0 2px 4px $shadow;
  }
  &__header {
    font-size: 1.5rem;
    border-top: 0.25rem solid $red;
    border-bottom: none;
    box-shadow: none;
  }
  &__header_success {
    font-size: 1.5rem;
    border-top: 0.25rem solid $green;
    border-bottom: none;
    box-shadow: none;
  }
  &__header_normal {
    font-size: 1.5rem;
    border-top: 0.25rem solid $grey;
    border-bottom: none;
    box-shadow: none;
  }
  &__wrapper {
    box-shadow: 0 2px 4px $shadow;
    border-radius: 0.375em 0.375em 0 0;
    overflow: hidden;
  }
  &__divider {
    position: relative;
    height: $cutout-size;
    background-color: $bg;
    margin-left: ($cutout-size / 2);
    margin-right: ($cutout-size / 2);
    &::after {
      content: '';
      position: absolute;
      height: 50%;
      width: 100%;
      top: 0;
      border-bottom: 2px dashed $divider;
    }
  }
  &__notch {
    position: absolute;
    left: ($cutout-size / 2) * -1;
    width: $cutout-size;
    height: $cutout-size;
    overflow: hidden;
    &::after {
      content: '';
      position: relative;
      display: block; 
      width: ($cutout-size * 2);
      height: ($cutout-size * 2);
      right: 100%;
      top: -50%;
      border: ($cutout-size / 2) solid $bg;
      border-radius: 50%;
      box-shadow: inset 0 2px 4px $shadow;
    }
    &--right {
      left: auto;
      right: ($cutout-size / 2) * -1;
      &::after {
        right: 0;
      }
    }
  }

}

.order-success{
    background-color: grey;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fbfbfb;
}

.ticket{
    margin: 45px auto;
    background-color: white;
    // width: 80%;
    border-radius: 20px;
    overflow: auto;
}
.child_ticket{
    padding: 25px 30px;
    padding-bottom: 0px;
}

.shape_left {
    display: block;
    width:30px;
    height:30px;    
    position: absolute;
    background-color: gray;
    top: 24%;
    left: 5%;
    border-radius: 50%;
    background-color: #fbfbfb;
}

@media only screen and (min-width: 768px) {
    .shape_left, .shape_right {
        display: none;
    }
}

.shape_right {
    width:30px;
    height:30px;    
    position: absolute;;
    background-color: gray;
    top: 24%;
    right: 5%;
    border-radius: 50%;
    background-color: #fbfbfb;
}


.tick{
    top: 20px;
    width: 50px;
    height: 50px;
}

.ticket_row{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
}

.man_count {
    margin: 10px 0;
}

hr {
    border:none;
    border-top:1.5px dashed lightgray;
    color:#fff;
    background-color:#fff;
    height:1px;
    width:90%;
    margin: 10px;
  }
  

.booking-btn{
    margin: 0;
}

.image{
    width: 40px;
    height: 40px;
}

.name{
    margin-top: 0px;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
}

.venue{
    font-size: 13px;
    margin: 2px 0px 2px 0px;
    font-weight: 600;
    text-align: center;
}

.time{
    font-size: 11px;
    color: gray;
    margin: 2px 0px 2px 0px;
    text-align: center;
}

.text_small{
    font-size: 12px;
    color: gray;
}

.text_big{
    font-size: 15px;
    font-weight: 700;
}

.details{
    font-weight: 500;
    font-family: monospace;
}

.ticket_column{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 45%;
}

.booking_date {
    width: 100%;
}

.qr_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.barcode_text{
    font-size: 14px;
    font-family: monospace;
    margin-top: 0px;
}

.booking-btn{
    margin: 5px;
    padding: 10px;
    border-radius: 10px;
    border: none;
    background-color: #567DF4;
    color: white;
    width: auto;
    font-size: smaller;
    font-weight: 600;
}

.email_text {
    margin: 2px 0;
}
