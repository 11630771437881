body {
  background: #eff5fc !important;
}

.owl-dots {
  position: absolute;
  right: 0;
}

.products .owl-prev {
  position: absolute;
  top: 30%;
  left: 0%;
  display: block !important;

  @media (max-width: 600px) {
    left: 5%;
  }
}

.products .owl-next {
  position: absolute;
  top: 30%;
  right: 0%;
  display: block !important;

  @media (max-width: 600px) {
    right: 5%;
  }
}

.slider-data .slider {
  position: relative;
}

.slider-data .slider h4 {
  position: absolute;
  color: #fff;
  font-size: 24px;
  z-index: 1;
  top: 80%;
  padding: 15px;
}

.store_look {
  padding: 20px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 35px;
  background-color: white;
  box-shadow: 0 0 0 2px rgb(255, 255, 255), 0.3em 0.3em 1em rgba(0, 0, 0, 10%);
  border-radius: 20px;
  margin-top: 60px !important;
}

.products_list {
  padding: 20px;
  max-width: 80%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 120px;
  background-color: white;
  box-shadow: 0 0 0 2px rgb(255, 255, 255), 0.3em 0.3em 1em rgba(0, 0, 0, 10%);
  border-radius: 20px;
}

@media (max-width: 1336px) {
  .store_look {
    padding: 20px;
    max-width: 100%;
    margin: auto;
    display: flex;
    justify-content: space-between;
  }
}

@media (max-width: 1024px) {
  .store_look {
    padding: 20px;
    max-width: 100%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .videoplay_img {
    margin-bottom: 20px;
  }
}

@media (max-width: 535px) {
  .play_image {
    #playimg {
      width: 70px;
      height: 70px;
    }
  }

  .modal-content {
    margin: auto;
    border: none;
    width: 65%;
    height: 50%;
  }

  .bannerDesc {
    width: 80% !important;
    font-size: 32px !important;
  }

  .bannerclocktime {
    display: none !important;
  }
}

.bannerclocktime {
  display: flex;
}

.videoplay_img {
  filter: brightness(70%);
  display: flex;
  justify-content: center;

  #selectimg {
    min-width: 300px;
    max-height: 400px;
    border-radius: 20px;
  }

  .play_image {
    display: block;
    position: absolute;
    // left: 43%;
    top: 35%;
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100px;
    max-height: 100px;
  }
}

.play_image {
  #playimg {
    max-width: 100px;
    max-height: 100px;
  }
}

.videoplay_img:hover {
  // filter: brightness(100%);
  cursor: pointer;
}

.modal {
  display: none;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.youtube-video {
  min-width: 100px;
  height: 700px;
}

.modal-content {
  margin: auto;
  position: absolute;
  top: 15%;
  left: 10%;
  border: none;
  width: 80%;
  min-height: 40%;
}

.registerBanner .owl-stage {
  height: 100vh !important;
}

.registerBanner .owl-dots {
  display: flex !important;
  flex-direction: column !important;
  gap: 20px !important;
  top: 50% !important;
  // bottom: auto!important;
  right: 10% !important;
  left: auto !important;
  margin-top: 0 !important;
  transform: translateY(-50%);
}

h2.product-sec-title {
  font-size: 64px !important;
  color: #202434 !important;
  font-weight: 600 !important;

  @media (max-width: 575px) {
    font-size: 25px !important;
  }
}

.bannerDesc {
  margin-top: 1rem;
  width: 100%;
  max-width: 650px;
  font-size: 64px;
  font-weight: 600;
}
